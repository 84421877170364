import React from "react"
import moment from "moment"
import Disclaimer from "./investors/Disclaimer"
export default function SingleMfnItemClient({ data }) {
  const [disclaimer, setDisclamer] = React.useState(true)
  const [confirm, setConfirm] = React.useState(false)
  const handleChange = () => {
    setDisclamer(!disclaimer)
  }
  const handleChange2 = () => {
    setConfirm(true)
  }
  moment().format()
  const date = moment(data.items[0].content.publish_date).format("YYYY-MM-DD")
  const tags = data.items[0].properties.tags
  console.log(tags)
  return (
    <div className="max-w-4xl mx-auto py-8 md:py-16 space-y-8 px-4 md:px-0">
      <div>
        {" "}
        {tags.includes("cus:disclaimer") && (
          <div>
            {disclaimer && (
              <div>
                <Disclaimer />
                <div className="flex flex-row space-x-4 mt-8">
                  <button
                    className="bg-black text-white rounded-full py-2 px-3 text-sm"
                    onClick={handleChange}
                  >
                    Ja, jag godkänner
                  </button>
                  <button
                    onClick={handleChange2}
                    className="bg-black text-white rounded-full py-2 px-3 text-sm"
                  >
                    Nej, jag godkänner inte
                  </button>
                </div>
                <div className="text-sm pt-4">
                  {confirm &&
                    "Du kan inte nå innehållet utan att bekräfta den nödvändiga informationen."}
                </div>
              </div>
            )}
          </div>
        )}
        <div>
          {(!disclaimer || !tags.includes("cus:disclaimer")) && (
            <>
              {" "}
              <header className="space-y-4">
                <h1 className="text-4xl text-brand font-bold text-primary">
                  {data.items[0].content.title}
                </h1>
                <div className="block text-secondary"> {date}</div>
              </header>
              <article>
                {" "}
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.items[0].content.html,
                  }}
                  className="space-y-4"
                />
              </article>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
