import React from "react"
import { Router } from "@reach/router"
import Layout from "../../components/layout"

import NewsClientSingle from "../../components/NewsClientSingle"

const App = props => {
  return (
    <Layout>
      <Router basepath="/app">
        <NewsClientSingle path="/newsitem/*" clientprops={props} />
      </Router>
    </Layout>
  )
}
export default App
