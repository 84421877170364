import React from "react"
import { useState, useEffect } from "react"
import SingleMfnItemClient from "./SingleMfnItemClient"
const isBrowser = () => typeof window !== "undefined"
export default function NewsClientSingle({ clientprops }) {
  const loc = clientprops.location.pathname
  const slug = loc.substring(14)

  const [mfnItem, setMfnItem] = useState(null)
  console.log(mfnItem)
  useEffect(() => {
    fetch(
      `https://feed.mfn.se/v1/feed/14a41db9-6398-466d-98cf-e53902b6b5bb.json?news-slug=${slug}`
    )
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        setMfnItem(previousData =>
          Object.is(previousData, resultData) ? previousData : resultData
        )
      }) // set data for the number of stars
  }, [])

  return (
    <div className="max-w-2xl mx-auto ">
      {mfnItem === null ? (
        ""
      ) : (
        <div>
          <SingleMfnItemClient data={mfnItem} />
        </div>
      )}
    </div>
  )
}
